<template>
  <div class="login">
    <div class="top">
      <img src="/novartis.png" alt="">
      <div>
        <p class="title">{{ $store.getters.config.title }}</p>
        <p class="logindt">{{ $store.getters.config.login_dt }}</p>
        <p>Cet évènement est destiné des professionnels de santé pouvant résider hors de France</p>
      </div>
      <p class="subtitle">{{ $store.getters.config.subtitle }}</p>
    </div>
    <div class="bottom">
      <div class="box">
        <template v-if="$store.getters.state == 'postshow'">
          <div class="field">
            <p>Cet évènement est maintenant terminé</p>
          </div>
        </template>
        <template v-else>
          <template v-if="error">
            <div class="field">
              <p>{{ error }}</p>
            </div>
            <div class="field">
              <div class="button" @click="unsetError">REESSAYER</div>
            </div>
          </template>
          <template v-else>
            <div class="field">
              <p>Adresse e-mail</p>
              <input type="text" v-model="email">
            </div>
            <div class="field">
              <p>Mot de passe</p>
              <input type="password" v-model="password">
            </div>
            <div class="field">
              <label for="privacy">
                <input type="checkbox" id="privacy" v-model="agreed"> Je comprends que mes données ne sont utilisées qu’à des fins d’organisation de cet événement.
              </label>
            </div>
            <div class="field">
              <div class="button" @click="login">ENTRER</div>
            </div>
          </template>
        </template>
      </div>
    </div>
<!--    <div class="form">
      <template v-if="error">
        <div class="field">
          <p>Les informations fournies n'ont pas permis de vous identifier</p>
        </div>
        <div class="button" @click="unsetError">
          Réessayer
        </div>
      </template>
      <template v-else>
        <p class="title">Connexion</p>
        <div class="field">
          <input placeholder="Email" type="email" v-model="email">
        </div>
        <div class="field">
          <input placeholder="Mot de passe" type="password" v-model="password">
        </div>
        <div class="field">
          <div class="button" @click="login">
            Entrer
          </div>
        </div>
      </template>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "Login",
  data(){
    return {
      email: '',
      password: '',
      agreed: false,
      error: false
    }
  },
  methods: {
    async login(){
      try {
        if(this.password == '' || this.email == ''){
          this.error = 'Veuillez remplir tous les champs'
        }
        else if(this.agreed == false){
          this.error = 'Veuillez confirmer que vous avez compris que vos données ne sont utilisées qu’à des fins d’organisation de cet événement.'
        }
        else {
          const { data } = await this.$axios.post('/login.php', {
            email: this.email,
            password: this.password
          })

          localStorage.setItem('token', data.token)
          this.$store.dispatch('setUser', data.user)
          this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token

          this.$router.push('/')
        }
      }
      catch(e){
        this.error = 'Identifiants erronés'
      }
    },
    unsetError(){
      this.error = false
    }
  }
}
</script>

<style lang="scss"scoped>
$blue: #0060AF;
$red: #B21F1F;
$orange: #D15F25;
$yellow: #EC9A1E;

  .form {
    border: solid 2px #AAAAAA;
    padding: 15px;
    border-radius: 15px;
  }

  .top {
    width: 100%;
    height: 37.5vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 6vh;
    justify-content: space-around;

      img {
        width: 30%;
        object-fit: contain;
      }
  }

  .bottom {
    width: 100%;
    height: 62.5vh;
    background: url('/fond.jpg');
    background-size: cover;
  }

  .login {
    width: 100%;
  }

  .title {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    color: $blue;
  }

.logindt {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: $yellow;
}

.subtitle {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: $blue;
}

.box {
  width: 50%;
  background-color: white;
  margin: 0 auto;
  border: solid 3px $blue;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}

  .field {
    margin-bottom: 2vh;
    width: 100%;
    p {
      text-align: center;
      margin-bottom: 10px;
    }

    label {
      width: 70%;
      margin: 3vh auto;
      display: block;
    }
  }

  .field input {
    width: 70%;
    border-radius: 100px;
    border: solid $blue 2px;
    padding: 5px;
    display: block;
    margin: 0 auto;
    &[type="checkbox"] {
      display: inline;
      margin: 0;
      width: unset;
    }
  }

  .button {
    padding: 5px 10px;
    border: solid 2px $blue;
    text-align: center;
    font-weight: 700;
    border-radius: 100px;
    cursor: pointer;
    width: 20vh;
    margin: 0 auto;
  }

  .button:hover {
    color: white;
    background-color: $blue;
  }
</style>